// download-button.tsx
"use client";

import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Download } from "lucide-react";
import { downloadPdf, DownloadPdfResult } from "@/app/actions/downloadPDF";

export function DownloadButton() {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = async () => {
    setIsLoading(true);
    try {
      const result: DownloadPdfResult = await downloadPdf();
      if ("error" in result) {
        console.error(result.error);
        // Optionally, display an error message to the user
      } else {
        const link = document.createElement("a");
        link.href = result.url;
        link.download = result.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(result.url);
      }
    } catch (error) {
      console.error("Error in download process:", error);
      // Optionally, display an error message to the user
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button 
    variant="ghost" 
    onClick={handleDownload} 
    disabled={isLoading}
    className="h-10">
      {isLoading ? (
        "Downloading..."
      ) : (
        <>
          Download Resume
          <Download className="h-4 w-4" />
        </>
      )}
    </Button>
  );
}

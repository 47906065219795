"use client";

import Image from "next/image";
import { motion } from "framer-motion";
import { Button } from "./ui/button";
import Link from "next/link";
import { ArrowRight, Github, Linkedin } from "lucide-react";
import { DownloadButton } from "./download-button";
import { ScrollArea } from "./ui/scroll-area";
export default function Hero() {
  return (
    <section className="relative h-screen flex items-center justify-center">
      <ScrollArea className="h-max">
        <div className="container px-4 md:px-6">
          <div className="grid gap-6 lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_600px]">
            <motion.div
              className="flex flex-col justify-center space-y-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none">Michael Webb</h1>
                <p className="text-xl text-muted-foreground">Business Intelligence & Analytics Engineer</p>
              </div>
              <p className="max-w-[600px] text-muted-foreground md:text-xl">
                Turning complex data into clear, actionable insights that drive meaningful decisions. Passionate about
                leveraging analytics to solve real-world challenges and empower businesses.
              </p>
              <div className="flex flex-col gap-2 min-[400px]:flex-row">
                <Button className="h-10" asChild>
                  <Link href="/contact">
                    Get in Touch
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Link>
                </Button>
                <Button variant="outline" className="h-10" asChild>
                  <Link href="/projects">Projects</Link>
                </Button>
                <Button variant="outline" className="h-10" asChild>
                  <Link href="/career">Career</Link>
                </Button>
                <DownloadButton />
              </div>
              <div className="flex space-x-4">
                <a
                  href="https://github.com/Michael-Webb"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-muted-foreground hover:text-foreground"
                >
                  <Github className="h-6 w-6" />
                  <span className="sr-only">GitHub</span>
                </a>
                <a
                  href="https://linkedin.com/in/michael-lewis-webb"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-muted-foreground hover:text-foreground"
                >
                  <Linkedin className="h-6 w-6" />
                  <span className="sr-only">LinkedIn</span>
                </a>
              </div>
            </motion.div>
            <motion.div
              className="mx-auto aspect-square overflow-hidden rounded-full border-2 border-primary"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Image
                src="/images/Michael Headshot.jpg"
                alt="Michael Webb"
                width={400}
                height={400}
                className="object-cover"
                priority
              />
            </motion.div>
          </div>
        </div>
        <div className="absolute inset-0 -z-10 h-full w-full bg-background dark:bg-background bg-[linear-gradient(to_right,#8080800a_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] dark:bg-[linear-gradient(to_right,#ffffff0a_1px,transparent_1px),linear-gradient(to_bottom,#ffffff0a_1px,transparent_1px)] bg-[size:14px_24px]" />
      </ScrollArea>
    </section>
  );
}
